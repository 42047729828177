import Layout from '../components/Layout';
import ImageWrapper from '../components/ImageWrapper';
import React from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField, TextFieldProps } from 'formik-material-ui';
import { Button } from '@material-ui/core';
import { graphql, navigate } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { marked } from 'marked';
import './contact.scss';

interface Props {
  data: any;
}

interface ErrorType {
  name?: string;
  email?: string;
  message?: string;
}

const BLOCK_CLASSNAME = 'contact';

const encode = (data: any) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

const Contact: React.FC<Props> = ({ data }: Props) => {
  return (
    <Layout>
      <div className={BLOCK_CLASSNAME}>
        <div className={`${BLOCK_CLASSNAME}__profile`}>
          <ImageWrapper noClick>
            <GatsbyImage
              image={data.contentfulContact.contactImage.gatsbyImageData}
              loading="lazy"
              alt={data.contentfulContact.contactImage.title}
            />
          </ImageWrapper>
        </div>
        <div className={`${BLOCK_CLASSNAME}__form-container`}>
          <div className={`${BLOCK_CLASSNAME}__form-wrapper`}>
            <h1>Kontaktiere mich</h1>
            <div
              dangerouslySetInnerHTML={{
                __html: marked.parse(data.contentfulContact.description.description),
              }}
            />
            <Formik
              initialValues={{
                name: '',
                email: '',
                message: '',
              }}
              onSubmit={(values, actions) => {
                fetch('/', {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                  body: encode({ 'form-name': 'contact', ...values }),
                })
                  .then(() => {
                    actions.resetForm();
                    navigate(`/thanks`);
                  })
                  .catch(() => {
                    alert('Error');
                  })
                  .finally(() => actions.setSubmitting(false));
              }}
              validate={(values) => {
                const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
                const errors: ErrorType = {};
                if (!values.name) {
                  errors.name = 'Namen eingeben!';
                }
                if (!values.email || !emailRegex.test(values.email)) {
                  errors.email = 'Email Adresse ist nicht gültig!';
                }
                if (!values.message) {
                  errors.message = 'Nachricht eingeben!';
                }
                return errors;
              }}
            >
              {() => (
                <Form
                  className={`${BLOCK_CLASSNAME}__form`}
                  name="contact"
                  data-netlify={true}
                  netlify-honeypot="bot-field"
                  action="/pages/thanks"
                >
                  <input type="hidden" name="contact" value="Contact Form" />
                  <input type="hidden" name="bot-field" />
                  <Field variant="outlined" name="name" label="Name" component={TextField} />
                  <br />
                  <br />
                  <Field
                    variant="outlined"
                    name="email"
                    type="email"
                    label="Email"
                    component={TextField}
                  />
                  <br />
                  <br />
                  <Field
                    name="message"
                    label="Message"
                    render={({ children, ...props }: TextFieldProps) => (
                      <TextField {...props} multiline rows={4} variant="outlined" label="Nachricht">
                        {children}
                      </TextField>
                    )}
                  />
                  <br />
                  <br />
                  <Button variant="contained" type="submit" size="large">
                    Anfrage absenden
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ContactPage {
    contentfulContact {
      description {
        description
      }
      contactImage {
        title
        gatsbyImageData
      }
    }
  }
`;

export default Contact;
